"use client"

import { usePathname } from "next/navigation"
import React, { useCallback } from "react"
import clsx from "clsx"
import { LinkProps } from "../../../types"
import { AdviqoLink, Box, ExpandMoreIcon } from "../../_common"
import { Tag } from "../../Experts/Tags/Tags"
import { formatTestID } from "../utils"
import classes from "./NavigationMenuItem.module.scss"

interface INavigationMenuItemProps extends LinkProps {
  isActive: boolean
  defaultOnLinkClick: () => void
  onToggleActive: React.MouseEventHandler
}

const NavigationMenuItem: React.FC<INavigationMenuItemProps> = ({
  title,
  url,
  dataTestId,
  links,
  defaultOnLinkClick,
  onClick,
  onToggleActive,
  isActive,
  showNewLabel,
  style,
}) => {
  const path = usePathname()

  const mainLinkClasses = clsx(classes.menuLink, {
    [classes.bold]: style?.bold,
  })

  const hasSubItems = links && links.length > 0

  const menuItemClasses = clsx(classes.menuItem, {
    [classes.menuItemOpen]: isActive,
  })

  const dropdownClasses = clsx(classes.dropdown, {
    [classes.dropdownOpen]: isActive,
  })

  const subMenuOpenerClasses = clsx(classes.subMenuOpener, {
    [classes.subMenuOpenerOpened]: isActive,
  })

  const onSubMenuOpenerClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()

      onToggleActive(e)
    },
    [onToggleActive]
  )

  return (
    <Box className={menuItemClasses}>
      <Box className={classes.menuLinkWrapper}>
        <AdviqoLink
          className={mainLinkClasses}
          to={url}
          onClick={onClick || defaultOnLinkClick}
          data-testid={dataTestId}
        >
          {title}
          {showNewLabel && (
            <Tag clickable={false} rootClassName={classes.tag} label="is_new" />
          )}
        </AdviqoLink>
        {hasSubItems && (
          <button
            className={classes.subMenuOpenerWrapper}
            type="button"
            onClick={onSubMenuOpenerClick}
            data-testid={`${dataTestId}-arrow`}
            name="toggle-menu-item"
          >
            <ExpandMoreIcon className={subMenuOpenerClasses} />
          </button>
        )}
      </Box>
      {hasSubItems && (
        <div className={dropdownClasses}>
          <ul className={classes.subMenu} data-testid={`submenu-${title}`}>
            {links?.map((subItem) => (
              <li key={subItem.id} className={classes.subMenuItem}>
                <AdviqoLink
                  to={subItem.url}
                  className={clsx(classes.subMenuLink, {
                    [classes.highlight]: subItem.url === path,
                  })}
                  onClick={subItem.onClick || defaultOnLinkClick}
                  data-testid={`navigation-subcategory-${formatTestID(
                    subItem.title
                  )}`}
                >
                  {subItem.title}
                  {subItem?.showNewLabel && (
                    <Tag
                      clickable={false}
                      rootClassName={classes.tag}
                      label="is_new"
                    />
                  )}
                </AdviqoLink>
              </li>
            ))}
          </ul>
        </div>
      )}
    </Box>
  )
}

export default NavigationMenuItem
