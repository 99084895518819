import { LinkProps } from "../../types"
import { INavigationMenu } from "../../graphql/contentful-schema"
import { isNavigationItem, isSlugOrArticleOrPage } from "../../utils/typeGuards"
import { buildLinkUrl } from "../../utils/slugUtils"

/**
 * since we operating with slugs (Page & NavigationMenu have only slugs), we KNOW resulted url MUST be internal (absolute path)
 * feel free to extend logic if your model supports "raw" links or something else
 */
const makeUrl = (linkSlug: string, parentSlug: string | undefined) =>
  parentSlug ? `/${parentSlug}/${linkSlug}` : `/${linkSlug}`

export function normalizeNavItems(
  itemsList: INavigationMenu["navigationSubLinksCollection"]["items"]
): LinkProps[] {
  return itemsList
    .map<LinkProps | null>((item) => {
      if (isSlugOrArticleOrPage(item)) {
        const {
          sys: { id },
          title,
          showNewLabel,
        } = item

        return {
          id,
          title,
          showNewLabel,
          url: buildLinkUrl(item),
        }
      }

      if (isNavigationItem(item)) {
        const {
          mainLink: {
            title,
            slug,
            sys: { id },
            showNewLabel,
            parentSlug,
          },
          mainLinkSubTitle,
          navigationSubLinksCollection: { items = [] },
        } = item

        return {
          id,
          title,
          url: makeUrl(slug, parentSlug?.slug),
          subListTitle: mainLinkSubTitle,
          showNewLabel,
          links: normalizeNavItems(items),
        }
      }

      return null
    })
    .filter((item): item is LinkProps => Boolean(item))
}

function findActiveItemCallback(item: LinkProps, path: string) {
  return (
    (path && item.url && path.includes(item.url)) ||
    item.links?.find(
      (subItem) => path && subItem.url && path.includes(subItem.url)
    )
  )
}

export const getActiveTopLevelItem = (
  navItems: LinkProps[],
  path: string
): LinkProps | undefined => {
  return navItems.find((item) => findActiveItemCallback(item, path))
}

export const getActiveTopLevelItemIndex = (
  navItems: LinkProps[],
  path: string
): number => {
  return navItems.findIndex((item) => findActiveItemCallback(item, path))
}

interface IMenuIteratorProps {
  (links: LinkProps[] | undefined, path: string, parentLink?: LinkProps):
    | LinkProps
    | undefined
}

export const getActiveLevelItem = (
  navItems: LinkProps[] = [],
  path: string
): LinkProps | undefined => {
  let itemWithChildren

  const iterator: IMenuIteratorProps = (
    links: LinkProps[] = [],
    path: string,
    parentLink?: LinkProps
  ) =>
    links?.find((link) => {
      if (link.url === path) {
        itemWithChildren = link.links ? link : parentLink
      }

      return iterator(link.links, path, link)
    })

  iterator(navItems, path)

  return itemWithChildren
}
