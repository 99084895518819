import React from "react"

import clsx from "clsx"
import { LinkProps } from "../../types"
import { AdviqoLink, Box } from "../_common"
import { Tag } from "../Experts/Tags/Tags"

interface IListItemProps {
  url: string
  path?: string
  activeMenuUrl?: string
  listItemActiveClassName?: string
  listSubItemActiveClassName?: string
  children: React.ReactNode
}

type INavigationListProps = Pick<
  IListItemProps,
  "listSubItemActiveClassName" | "listItemActiveClassName" | "path"
> & {
  items: LinkProps[]
  listClassName?: string
  listTitleClassName?: string
  tagClassName?: string
  onMenuLinkClick?: (e: React.MouseEvent, link: LinkProps) => void
  activeMenuUrl?: string
}

const ListItem: React.FC<IListItemProps> = ({
  children,
  listItemActiveClassName = "",
  listSubItemActiveClassName = "",
  path,
  url,
  activeMenuUrl,
}) => {
  const shouldOpenSubMenu = path?.includes(url)

  const shouldHighlight = activeMenuUrl === url || path === url

  const linkClasses = clsx({
    [listItemActiveClassName]: shouldOpenSubMenu,
    [listSubItemActiveClassName]: shouldHighlight,
  })

  return (
    <Box component="li" className={linkClasses}>
      {children}
    </Box>
  )
}

const RenderNavItems = ({
  items,
  path,
  listItemActiveClassName,
  listSubItemActiveClassName,
  listTitleClassName,
  tagClassName,
  onMenuLinkClick,
  activeMenuUrl,
}: INavigationListProps) => {
  return items.map((item) => {
    const { id, links, url, title, showNewLabel } = item

    return (
      <ListItem
        listItemActiveClassName={listItemActiveClassName}
        listSubItemActiveClassName={listSubItemActiveClassName}
        path={path}
        url={url}
        key={url}
        activeMenuUrl={activeMenuUrl}
      >
        <AdviqoLink
          to={url}
          data-testid={id}
          onClick={(e) => {
            if (onMenuLinkClick) {
              onMenuLinkClick(e, item)
            }
          }}
        >
          {title}

          {showNewLabel && (
            <Tag
              clickable={false}
              rootClassName={tagClassName}
              label="is_new"
            />
          )}
        </AdviqoLink>

        {links && (
          <Box component="ul">
            {RenderNavItems({
              items: links,
              path,
              listItemActiveClassName,
              listSubItemActiveClassName,
              listTitleClassName,
              tagClassName,
              onMenuLinkClick,
            })}
          </Box>
        )}
      </ListItem>
    )
  })
}

export const NavigationList: React.FC<INavigationListProps> = ({
  items,
  path,
  listClassName,
  listTitleClassName,
  listItemActiveClassName,
  listSubItemActiveClassName,
  tagClassName,
  onMenuLinkClick,
  activeMenuUrl,
}) => {
  return (
    <Box component="ul" className={listClassName}>
      {RenderNavItems({
        items,
        path,
        listItemActiveClassName,
        listSubItemActiveClassName,
        listTitleClassName,
        tagClassName,
        onMenuLinkClick,
        activeMenuUrl,
      })}
    </Box>
  )
}
