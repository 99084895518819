import React, { useEffect, useState } from "react"

import { AppBarProps } from "@mui/material/AppBar"
import { AppBar } from "../_common"
import { useDomRefs } from "../../hooks/domRefs"
import { CONFIG } from "./consts"
import { INewHeaderRenderSupport } from "./types"
import useWithDomRef from "../../hooks/domRefs/useWithDomRef"

type IAppBarWithSideEffectsProps = Partial<INewHeaderRenderSupport> &
  Pick<
    React.ComponentProps<typeof AppBar>,
    "appBarClassName" | "contaitnerClassName" | "children"
  >

const AppBarWithSideEffects: React.FC<IAppBarWithSideEffectsProps> = (
  props
) => {
  const { headerWithOfferBar, headerWithBanner, children } = props

  const { getRefHeight } = useDomRefs()

  const navBarRef = useWithDomRef("navbar")

  const [appBarPosition, setAppBarPosition] = useState<AppBarProps["position"]>(
    "absolute"
  )

  const [bannerHeight, setBannerHeight] = useState<number>(
    headerWithBanner ? CONFIG.BANNER.height : 0
  )
  const [offerBarHeight, setOfferBarHeight] = useState<number>(
    headerWithOfferBar ? CONFIG.OFFER_BAR.height : 0
  )

  useEffect(() => {
    const scrollHandler = () => {
      const position =
        window.scrollY - (offerBarHeight + bannerHeight) > 0
          ? "fixed"
          : "absolute"

      setAppBarPosition(position)
    }

    const resizeHandler = () => {
      if (headerWithOfferBar) {
        setOfferBarHeight(getRefHeight("offerbar"))
      }
      if (headerWithBanner) {
        setBannerHeight(getRefHeight("banner"))
      }
    }

    window.addEventListener("scroll", scrollHandler)
    window.addEventListener("resize", resizeHandler)

    return () => {
      window.removeEventListener("scroll", scrollHandler)
      window.removeEventListener("resize", resizeHandler)
    }
  }, [
    bannerHeight,
    getRefHeight,
    headerWithBanner,
    headerWithOfferBar,
    offerBarHeight,
  ])

  useEffect(() => {
    if (headerWithBanner) {
      setBannerHeight(getRefHeight("banner"))
      setOfferBarHeight(0)
    } else if (headerWithOfferBar) {
      setOfferBarHeight(getRefHeight("offerbar"))
      setBannerHeight(0)
    } else {
      setOfferBarHeight(0)
      setBannerHeight(0)
    }
  }, [getRefHeight, headerWithBanner, headerWithOfferBar])

  const appBarOffset =
    appBarPosition === "fixed" ? 0 : offerBarHeight + bannerHeight

  return (
    <AppBar
      {...props}
      navBarRef={navBarRef}
      position={appBarPosition}
      topOffset={appBarOffset}
    >
      {children}
    </AppBar>
  )
}

export default AppBarWithSideEffects
