"use client"

import React, { useRef, useEffect } from "react"
import clsx from "clsx"

import { AdviqoLink, useTheme, NextImage, alpha, Box } from "../_common"
import { useDomRefs } from "../../hooks/domRefs"
import { IOfferBarIcon } from "../../types"
import classes from "./OfferBar.module.scss"

interface OfferBarProps {
  text: JSX.Element | string
  appearance?: "regular" | "simple"
  url?: string
  fontColor?: string
  backgroundColor?: string
  icon?: IOfferBarIcon
  linkRelAttribute?: string
}

const OfferBar: React.FC<OfferBarProps> = ({
  text,
  url = undefined,
  appearance = "regular",
  fontColor,
  icon,
  backgroundColor,
  linkRelAttribute,
}) => {
  const offerBarRef = useRef<HTMLDivElement>(null)
  const theme = useTheme()
  const { addRef, delRef } = useDomRefs()

  const wrapperClassName = clsx(classes.wrapper, {
    [classes.wrapperSimple]: appearance === "simple",
  })
  const textClassName = clsx(classes.text, {
    [classes.textSimple]: appearance === "simple",
  })

  useEffect(() => {
    if (offerBarRef.current) {
      addRef("offerbar", offerBarRef)
    }

    return () => {
      delRef("offerbar")
    }
  }, [])

  const renderOfferBarWrapper = (
    <Box
      sx={{
        backgroundColor: backgroundColor,
        "&:hover": Boolean(url)
          ? {
              backgroundColor: alpha(
                backgroundColor || theme.palette.background.default,
                0.9
              ),
            }
          : undefined,
      }}
      className={wrapperClassName}
      ref={offerBarRef}
    >
      {icon && (
        <div className={classes.iconWrapper}>
          <NextImage
            src={icon.url}
            width={(Number(theme.spacing(2.5)) / icon.height) * icon.width}
            height={Number(theme.spacing(2.5))}
            alt="Offerbar Icon"
          />
        </div>
      )}
      <Box
        sx={{
          color: fontColor || theme.palette.primary.main,
        }}
        className={textClassName}
      >
        {text}
      </Box>
    </Box>
  )

  return (
    <>
      {url ? (
        <AdviqoLink to={url} color="inherit" rel={linkRelAttribute}>
          {renderOfferBarWrapper}
        </AdviqoLink>
      ) : (
        renderOfferBarWrapper
      )}
    </>
  )
}

export default OfferBar
