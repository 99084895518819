import React, { useState } from "react"
import clsx from "clsx"

import { LinkProps } from "../../../types"
import NavigationMenuItem from "./NavigationMenuItem"
import classes from "./MainNavigationMenu.module.scss"

interface IMainNavigationMenuProps {
  items: LinkProps[]
  onLinkClick: () => void
  /**
   * Which menu is opened by default?
   * Pass -1 to none
   */
  defaultOpenedMenuIndex: number
  className?: string
}

const MainNavigationMenu: React.FC<IMainNavigationMenuProps> = ({
  items,
  onLinkClick,
  defaultOpenedMenuIndex,
  className,
}) => {
  const [openedMenuIndex, setOpenedMenuIndex] = useState<number>(
    defaultOpenedMenuIndex
  )
  const rootClasses = clsx(classes.root, className)

  const toggleActive = (index: number) => (e: React.MouseEvent) => {
    if (e) e.preventDefault()

    setOpenedMenuIndex(index === openedMenuIndex ? -1 : index)
  }

  const navItems = items.map((item, index) => {
    return (
      <li key={item.id} className={classes.navItem}>
        <NavigationMenuItem
          {...item}
          isActive={index === openedMenuIndex}
          defaultOnLinkClick={onLinkClick}
          onToggleActive={toggleActive(index)}
          dataTestId={item.dataTestId || `navigation-category-${item.title}`}
        />
      </li>
    )
  })

  return <ul className={rootClasses}>{navItems}</ul>
}

export default MainNavigationMenu
