"use client"

import clsx from "clsx"
import React from "react"

import { useAuthentication } from "../../hooks/authentication"
import { MyExpertsButton } from "../MyExperts"
import { Search } from "../Search"
import { Box } from "../_common"
import AppBarWithSideEffects from "./AppBarWithSideEffects"
import { MainNavigationMenu } from "./MainNavigationMenu"
import { useNavigationContext } from "./NavigationContext"
import NavigationLogo from "./NavigationLogo"
import { INavigationProps } from "./types"
import UserArea from "./UserArea"
import classes from "./DesktopNavigation.module.scss"

const DesktopNavigation: React.FC<INavigationProps> = ({
  navItems,
  headerWithOfferBar,
  headerWithBanner,
  headerWithNavBar,
  headerWithLogo,
}) => {
  const { searchExtended, setSearchExtended } = useNavigationContext()
  const { isAuthenticated } = useAuthentication()

  const logoClasses = clsx(classes.logo, classes.spacer)
  const innerClasses = clsx(classes.inner, classes.spacer)

  const navigationMenuClasses = clsx(classes.navigationMenu, classes.spacer, {
    [classes.navigationMenuHidable]: searchExtended,
  })

  const searchContainerClasses = clsx(classes.searchContainer, classes.spacer, {
    [classes.searchContainerOpened]: searchExtended,
    [classes.searchContainerOpenedExtraSpace]: searchExtended,
  })

  return (
    <AppBarWithSideEffects
      headerWithOfferBar={headerWithOfferBar}
      headerWithBanner={headerWithBanner}
    >
      {headerWithLogo && (
        <NavigationLogo autoCenter={false} className={logoClasses} />
      )}
      {headerWithNavBar && (
        <>
          <Box className={innerClasses}>
            <MainNavigationMenu
              items={navItems}
              onLinkClick={() => ({})}
              defaultOpenedMenuIndex={-1}
              className={navigationMenuClasses}
            />
            <Search
              extended={searchExtended}
              toggleExtend={setSearchExtended}
              containerClassName={searchContainerClasses}
            />
          </Box>
          {isAuthenticated && (
            <MyExpertsButton isTransparent={false} className={classes.spacer} />
          )}
          <UserArea
            className={classes.spacer}
            onLinkClick={() => ({})}
            isMobile={false}
          />
        </>
      )}
    </AppBarWithSideEffects>
  )
}

export default DesktopNavigation
