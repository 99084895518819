"use client"

import { usePathname } from "next/navigation"
import React from "react"

import clsx from "clsx"
import { AdviqoLink, Box } from "../../_common"
import { LinkProps } from "../../../types"
import { NavigationList } from "../../NavigationList"
import classes from "./SideNavigation.module.scss"

type ISideNavigationProps = Pick<
  React.ComponentProps<typeof NavigationList>,
  "onMenuLinkClick" | "activeMenuUrl"
> & {
  menuItem: LinkProps | undefined
  isSticky?: boolean
  testId?: string
}

const SideNavigation: React.FC<ISideNavigationProps> = ({
  menuItem,
  isSticky = false,
  onMenuLinkClick,
  activeMenuUrl,
  testId,
}) => {
  const path = usePathname()

  if (!menuItem?.links) return null

  const rootClasses = clsx(classes.container, {
    [classes.isSticky]: isSticky,
  })

  return (
    <Box className={rootClasses} data-testid={testId}>
      {menuItem.url && menuItem.title && (
        <AdviqoLink
          className={classes.listTitle}
          to={menuItem.url}
          data-testid={menuItem.url.replace("/", "")}
        >
          {menuItem.title}
        </AdviqoLink>
      )}

      <NavigationList
        items={menuItem.links}
        path={path}
        listClassName={classes.list}
        listTitleClassName={classes.listTitle}
        listItemActiveClassName={classes.active}
        listSubItemActiveClassName={classes.highlight}
        tagClassName={classes.tag}
        onMenuLinkClick={onMenuLinkClick}
        activeMenuUrl={activeMenuUrl}
      />
    </Box>
  )
}

export default SideNavigation
