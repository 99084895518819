import React from "react"
import { makeAttributesObject } from "../ContentItemList/utils"
import { AdviqoButton, Box } from "../_common"
import classes from "./CtaSection.module.scss"

interface ICtaSectionProps {
  ctaTitle?: string
  ctaLink?: string | null
  dataAttributes?: Record<string, string>
}

const CTASection: React.FC<ICtaSectionProps> = ({
  ctaTitle,
  ctaLink,
  dataAttributes,
}) => {
  if (!ctaTitle || !ctaLink) return null

  return (
    <Box {...makeAttributesObject(dataAttributes)}>
      <AdviqoButton
        color="secondary"
        label={ctaTitle}
        buttonClassName={classes.cta}
        labelClassName={classes.ctaLabel}
        to={ctaLink}
      />
    </Box>
  )
}
export default CTASection
