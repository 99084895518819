"use client"

import React, { ReactNode, useCallback, useRef, useState } from "react"
import Slider from "react-slick"
import clsx from "clsx"
import { PauseCircleFilled, PlayCircleFilled } from "@mui/icons-material"

import { Box } from "../_common/MaterialUI"
import ChevronIcon from "../_common/QuesticoIcon/ChevronIcon"
import classes from "./Carousel.module.scss"

interface ISliderButton {
  classNames?: string
  children?: ReactNode
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

interface ICarouselProps {
  withPlayButton: boolean
  children: ReactNode
}

const Button: React.FC<ISliderButton> = ({ classNames, onClick, children }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={clsx(classes.button, classNames)}
    >
      {children}
    </button>
  )
}

const Carousel: React.FC<ICarouselProps> = ({ children, withPlayButton }) => {
  const sliderRef = useRef<Slider>(null)
  const [autoplay, setAutoplay] = useState(true)

  const settings = {
    arrows: true,
    dots: true,
    easing: "ease-in",
    autoplay: true,
    infinite: true,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 6000,
    dotsClass: classes.dots,

    customPaging: () => {
      return <Button classNames={clsx(classes.button, classes.dot)} />
    },

    nextArrow: (
      <Button classNames={clsx(classes.sliderButton, classes.sliderNextButton)}>
        <ChevronIcon />
      </Button>
    ),

    prevArrow: (
      <Button classNames={clsx(classes.sliderButton, classes.sliderPrevButton)}>
        <ChevronIcon />
      </Button>
    ),

    responsive: [
      {
        breakpoint: 960,
        settings: {
          arrows: false,
        },
      },
    ],
  }

  const handlePauseButtonClick = useCallback(() => {
    setAutoplay(!autoplay)
    setTimeout(() => {
      if (autoplay) {
        sliderRef.current?.slickPause()
      } else {
        sliderRef.current?.slickPlay()
      }
    })
  }, [autoplay])

  return (
    <Box className={classes.root}>
      {/* @ts-ignore */}
      <Slider {...settings} ref={sliderRef}>
        {children}
      </Slider>

      {withPlayButton && (
        <Button
          classNames={clsx(classes.pauseButton)}
          onClick={handlePauseButtonClick}
        >
          {autoplay ? (
            <PauseCircleFilled color="inherit" />
          ) : (
            <PlayCircleFilled color="inherit" />
          )}
        </Button>
      )}
    </Box>
  )
}

export default Carousel
