"use client"

import React, { useState } from "react"

import { NavigationContext } from "./NavigationContext"

export const NavigationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [searchExtended, setSearchExtended] = useState<boolean>(false)

  return (
    <NavigationContext.Provider
      value={{
        searchExtended,
        setSearchExtended,
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
}
