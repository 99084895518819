"use client"

import { usePathname } from "next/navigation"
import React, { useCallback, useState } from "react"
import clsx from "clsx"
import { useTranslations } from "next-intl"

import { LinkProps, TranslateFn } from "../../types"
import { useAuthentication } from "../../hooks/authentication"
import { AppBar, Drawer as CommonDrawer } from "../_common"
import HamburgerIcon from "../_common/QuesticoIcon/HamburgerIcon"
import CloseIcon from "../_common/QuesticoIcon/CloseIcon"
import { getMenu } from "../MyQuestico/navigationItems"
import AppBarWithSideEffects from "./AppBarWithSideEffects"
import { MainNavigationMenu } from "./MainNavigationMenu"
import NavigationLogo from "./NavigationLogo"
import { INavigationProps } from "./types"
import ToolbarButton from "./ToolbarButton"
import UserArea from "./UserArea"
import { MyExpertsButton } from "../MyExperts"
import { useNavigationContext } from "./NavigationContext"
import { getActiveTopLevelItemIndex } from "../../components/utils/navigation"
import { Search } from "../Search"
import { getBrandDashboardSlug } from "../../utils/utils"
import classes from "./MobileNavigation.module.scss"

const makeMyQuesticoMenuItems = (
  onGoToOFEClick: React.MouseEventHandler,
  translate: TranslateFn
): LinkProps[] => [
  {
    id: "my-questico-items",
    title: `Mein ${translate("brandName")}`,
    url: `/${getBrandDashboardSlug()}`,
    dataTestId: "navbar-account",
    links: getMenu(translate("beraterSideNavigationTitle")).concat({
      id: "gotoofe",
      title: "",
      url: "",
      onClick: onGoToOFEClick,
    }),
  },
]

const customizeForAuthenticatedUser = (
  items: LinkProps[],
  onLogoutClick: React.MouseEventHandler,
  onGoToOFEClick: React.MouseEventHandler,
  translate: TranslateFn
) => {
  return [
    ...makeMyQuesticoMenuItems(onGoToOFEClick, translate),
    ...items,
    {
      id: "logout-menu-item",
      title: "Abmelden",
      url: "",
      dataTestId: "navbar-logout",
      onClick: onLogoutClick,
      style: {
        bold: true,
      },
    } as LinkProps,
  ]
}

interface IDrawerProps extends Pick<INavigationProps, "navItems"> {
  opened: boolean
  toggleDrawer: (opened: boolean) => void
}

export const Drawer: React.FC<IDrawerProps> = ({
  opened,
  toggleDrawer,
  navItems,
}) => {
  const path = usePathname()
  const translate = useTranslations()

  const { logout, isAuthenticated } = useAuthentication()

  const onNavigationItemClick = useCallback(() => {
    toggleDrawer(false)
  }, [toggleDrawer])

  const onGoToOFEClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      onNavigationItemClick()
    },
    [onNavigationItemClick]
  )

  const onLogoutClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      logout()
    },
    [logout]
  )

  const finalItems = isAuthenticated
    ? customizeForAuthenticatedUser(
        navItems,
        onLogoutClick,
        onGoToOFEClick,
        translate
      )
    : navItems

  const activeMenuIndex = getActiveTopLevelItemIndex(finalItems, path)

  return (
    <CommonDrawer
      anchor="left"
      opened={opened}
      toggleDrawer={toggleDrawer}
      testId="mobile-navigation-paper"
    >
      <AppBar
        contaitnerClassName={classes.drawerAppBarContainer}
        appBarClassName={classes.drawerAppBar}
        component="div"
      >
        <NavigationLogo autoCenter={false} />
        <ToolbarButton
          buttonClassName={classes.spacer}
          onClick={() => toggleDrawer(false)}
          icon={<CloseIcon />}
          testId="navbar-mobile-close"
          name="menu-close"
        />
      </AppBar>
      <UserArea
        onLinkClick={onNavigationItemClick}
        isMobile
        className={classes.userArea}
      />
      <MainNavigationMenu
        items={finalItems}
        onLinkClick={onNavigationItemClick}
        defaultOpenedMenuIndex={activeMenuIndex === -1 ? 0 : activeMenuIndex}
      />
    </CommonDrawer>
  )
}

const MobileNavigation: React.FC<INavigationProps> = ({
  navItems,
  headerWithOfferBar,
  headerWithBanner,
  headerWithNavBar,
  headerWithLogo,
}) => {
  const { isAuthenticated } = useAuthentication()
  const { searchExtended, setSearchExtended } = useNavigationContext()

  const [drawerOpened, setDrawerOpened] = useState(false)

  const searchContainerClasses = clsx(classes.searchContainer, {
    [classes.searchContainerOpened]: searchExtended,
  })

  const actionsWrapperClasses = clsx(classes.actionsWrapper, classes.spacer)

  return (
    <>
      <AppBarWithSideEffects
        headerWithOfferBar={headerWithOfferBar}
        headerWithBanner={headerWithBanner}
      >
        {headerWithNavBar && (
          <ToolbarButton
            buttonClassName={classes.spacer}
            onClick={() => setDrawerOpened(true)}
            icon={<HamburgerIcon />}
            testId="navbar-mobile-burger"
            name="menu-open"
          />
        )}
        {headerWithLogo && <NavigationLogo autoCenter />}
        {headerWithNavBar && (
          <>
            <div className={actionsWrapperClasses}>
              {isAuthenticated && !searchExtended && (
                <MyExpertsButton isTransparent={false} />
              )}
              <Search
                extended={searchExtended}
                toggleExtend={setSearchExtended}
                containerClassName={searchContainerClasses}
                allowToCloseIfNoValue
                dropdownMode="fullscreen"
              />
            </div>
          </>
        )}
      </AppBarWithSideEffects>
      <Drawer
        navItems={navItems}
        opened={drawerOpened}
        toggleDrawer={setDrawerOpened}
      />
    </>
  )
}

export default MobileNavigation
