import React from "react"

import { IIconProps } from "./types"

const HamburgerIcon: React.FC<IIconProps> = ({
  width = "100%",
  height = "100%",
  color = "currentColor",
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
      <path d="M16 1H0V2.5H16V1Z" fill={color} />
      <path d="M16 7.5H0V9H16V7.5Z" fill={color} />
      <path d="M16 14H0V15.5H16V14Z" fill={color} />
    </svg>
  )
}

export default HamburgerIcon
