import React from "react"

export interface INavigationContext {
  searchExtended: boolean
  setSearchExtended: (nextExtended: boolean) => void
}

export const NavigationContext = React.createContext<INavigationContext>({
  searchExtended: false,
  setSearchExtended: (extended) => extended,
})

export const useNavigationContext = () => React.useContext(NavigationContext)
