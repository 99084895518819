import { LinkProps, PageSlug } from "../../types"
import { getBrandDashboardSlug, isQuesticoBrand } from "../../utils/utils"

export const getMenu = (beraterTitle: string): LinkProps[] => {
  const brandIsQuestico = isQuesticoBrand()
  const dashboardSlug = getBrandDashboardSlug()

  return [
    {
      id: PageSlug.BERATER,
      title: beraterTitle,
      url: `/${dashboardSlug}/${PageSlug.BERATER}`,
      showNewLabel: false,
    },
    {
      id: PageSlug.KONTO,
      title: "Konto & Zahlungen",
      url: `/${dashboardSlug}/${PageSlug.KONTO}`,
      showNewLabel: false,
    },
    {
      id: PageSlug.CHAT,
      title: "Chat",
      url: `/${dashboardSlug}/${PageSlug.CHAT}`,
      showNewLabel: false,
    },
    {
      id: PageSlug.QMAIL,
      title: brandIsQuestico ? "Q-Mail" : "V-Mail",
      url: `/${dashboardSlug}/${
        brandIsQuestico ? PageSlug.QMAIL : PageSlug.VMAIL
      }`,
      showNewLabel: false,
    },
    {
      id: PageSlug.GUTSCHEIN,
      title: "Gutschein",
      url: `/${dashboardSlug}/${PageSlug.GUTSCHEIN}`,
      showNewLabel: false,
    },
    {
      id: PageSlug.PROFIL,
      title: "Profil",
      url: `/${dashboardSlug}/${PageSlug.PROFIL}`,
      showNewLabel: false,
    },
  ]
}
