import React from "react"
import clsx from "clsx"

import { AdviqoButton } from "../_common"
import classes from "./ToolbarButton.module.scss"

type IToolbarButtonProps = Omit<
  React.ComponentProps<typeof AdviqoButton>,
  "round" | "color"
>

const ToolbarButton: React.FC<IToolbarButtonProps> = ({
  buttonClassName,
  ...props
}) => {
  return (
    <AdviqoButton
      {...props}
      buttonClassName={clsx(classes.toolbarButton, buttonClassName)}
      round
      color="custom"
    />
  )
}

export default ToolbarButton
