"use client"

import React, { ComponentProps } from "react"

import clsx from "clsx"
import { AdviqoLink, Box, Container, useMediaQuery, useTheme } from "../_common"

import RichText from "../RichText"
import { ContentfulRichText, TextAlign, VerticalAlign } from "../../types"
import CtaSection from "./CtaSection"
import { CONFIG } from "../NewNavigation/consts"
import ConditionalWrap from "ui-lib/ConditionalWrap"
import classes from "./HeroImage.module.scss"

interface IHeroImageProps extends ComponentProps<typeof CtaSection> {
  imageSrc: string
  imageMobileSrc?: string
  colorDesktop: string
  colorMobile: string
  horizontalAlignDesktop: TextAlign
  horizontalAlignMobile: TextAlign
  verticalAlignDesktop: VerticalAlign
  verticalAlignMobile: VerticalAlign
  overlay?: boolean
  withGradientDesktop?: boolean
  withGradientMobile?: boolean
  contentDesktop?: ContentfulRichText | null
  contentMobile?: ContentfulRichText | null
  horizontalAlignImageDesktop: TextAlign
  horizontalAlignImageMobile: TextAlign
  verticalAlignImageDesktop: VerticalAlign
  verticalAlignImageMobile: VerticalAlign
  contentTextShadow: boolean
}

type IContent = ComponentProps<typeof CtaSection> & {
  withGradient: boolean
  image: string
  horizontalAlignImage: TextAlign
  verticalAlignImage: VerticalAlign
  overlay: boolean
  contentTextShadow: boolean
  horizontalAlign: TextAlign
  verticalAlign: VerticalAlign
  color: string
  content: ContentfulRichText
}

const verticalAlignMap = {
  top: "flex-start",
  center: "center",
  bottom: "flex-end",
}

const horizontalAlignMap = {
  left: "flex-start",
  center: "center",
  right: "flex-end",
  justify: "stretch",
}

const Content = ({
  withGradient,
  image,
  horizontalAlignImage,
  verticalAlignImage,
  overlay,
  contentTextShadow,
  horizontalAlign,
  verticalAlign,
  color,
  content,
  ctaTitle,
  ctaLink,
  dataAttributes,
}: IContent) => {
  const wrapperClasses = clsx(classes.wrapper, {
    [classes.textShadow]: contentTextShadow,
  })

  return (
    <Box
      className={classes.heroImage}
      sx={{
        backgroundImage: withGradient
          ? `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)), url(${image})`
          : `url(${image})`,
        backgroundPosition: `${horizontalAlignImage} ${verticalAlignImage}`,
        marginTop: overlay ? 0 : `${CONFIG.APP_BAR.height}px`,
      }}
    >
      <Container
        className={classes.container}
        sx={{
          paddingTop: `${overlay ? CONFIG.APP_BAR.height : 24}px`,
        }}
      >
        <Box
          className={wrapperClasses}
          sx={{
            alignItems: horizontalAlignMap[horizontalAlign],
            justifyContent: verticalAlignMap[verticalAlign],
            "& > *": {
              textAlign: horizontalAlign,
            },
            color,
          }}
        >
          <RichText content={content} />

          <CtaSection
            ctaTitle={ctaTitle}
            ctaLink={ctaLink}
            dataAttributes={dataAttributes}
          />
        </Box>
      </Container>
    </Box>
  )
}

const HeroImage: React.FC<IHeroImageProps> = ({
  imageSrc,
  imageMobileSrc,
  contentDesktop,
  contentMobile,
  verticalAlignDesktop,
  verticalAlignMobile,
  horizontalAlignDesktop,
  horizontalAlignMobile,
  colorDesktop,
  colorMobile,
  overlay = false,
  withGradientDesktop,
  withGradientMobile,
  ctaTitle,
  ctaLink,
  dataAttributes,
  horizontalAlignImageDesktop,
  horizontalAlignImageMobile,
  verticalAlignImageDesktop,
  verticalAlignImageMobile,
  contentTextShadow,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const contentOnMobile = contentMobile || contentDesktop

  return (
    <ConditionalWrap
      condition={Boolean(ctaLink && !ctaTitle?.trim())}
      wrap={(children) => <AdviqoLink to={ctaLink!}>{children}</AdviqoLink>}
    >
      <Box>
        {contentOnMobile && isMobile && (
          <Content
            withGradient={Boolean(withGradientMobile)}
            image={imageMobileSrc || imageSrc}
            horizontalAlignImage={horizontalAlignImageMobile}
            verticalAlignImage={verticalAlignImageMobile}
            overlay={overlay}
            contentTextShadow={contentTextShadow}
            horizontalAlign={horizontalAlignMobile}
            verticalAlign={verticalAlignMobile}
            color={colorMobile}
            content={contentOnMobile}
            ctaTitle={ctaTitle}
            ctaLink={ctaLink}
            dataAttributes={dataAttributes}
          />
        )}

        {contentDesktop && !isMobile && (
          <Content
            withGradient={Boolean(withGradientDesktop)}
            image={imageSrc}
            horizontalAlignImage={horizontalAlignImageDesktop}
            verticalAlignImage={verticalAlignImageDesktop}
            overlay={overlay}
            contentTextShadow={contentTextShadow}
            horizontalAlign={horizontalAlignDesktop}
            verticalAlign={verticalAlignDesktop}
            color={colorDesktop}
            content={contentDesktop}
            ctaTitle={ctaTitle}
            ctaLink={ctaLink}
            dataAttributes={dataAttributes}
          />
        )}
      </Box>
    </ConditionalWrap>
  )
}
export default HeroImage
