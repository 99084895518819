import React from "react"
import clsx from "clsx"

import { Logo } from "../_common"
import classes from "./NavigationLogo.module.scss"

interface INavigationLogoProps {
  /**
   * automatically centers logo via absolute positioning on mobile devices
   */
  autoCenter: boolean
  className?: string
}

const NavigationLogo: React.FC<INavigationLogoProps> = ({
  autoCenter,
  className,
}) => {
  const wrapperClassName = clsx(
    classes.logo,
    {
      [classes.absolutelyCentered]: autoCenter,
    },
    className
  )

  return (
    <Logo
      wrapperClassName={wrapperClassName}
      sizeVariation="full"
      withLink
    />
  )
}

export default NavigationLogo
