import React from "react"
import clsx from "clsx"
import { useTranslations } from "next-intl"

import { AdviqoButton, AdviqoLink } from "../_common"
import { IAuthLink } from "./types"
import { LogoutButton, RegisterButton } from "../_common/Buttons"
import { useAuthentication } from "../../hooks/authentication"
import { TAuthenticationAction } from "../../types"
import { getBrandDashboardSlug } from "../../utils/utils"
import classes from "./UserArea.module.scss"

interface IUserAreaProps {
  onLinkClick: () => void
  isMobile: boolean
  className?: string
}

const getLinks = (
  isAuthenticated: boolean,
  logout: () => void,
  goToAuth: (authAction?: TAuthenticationAction | undefined) => void,
  translate: (str: string) => string
): Array<IAuthLink> => {
  return isAuthenticated
    ? [
        {
          id: "account",
          title: `Mein ${translate("brandName")}`,
          to: `/${getBrandDashboardSlug()}`,
          as: `/${getBrandDashboardSlug()}`,
        },
        {
          id: "logout",
          title: "Abmelden",
          to: "",
          onClick: (e: React.MouseEvent) => {
            e.preventDefault()
            logout()
          },
        },
      ]
    : [
        {
          id: "login",
          title: "Login",
          to: "",
          dataTestid: "login",
          onClick: (e: React.MouseEvent) => {
            e.preventDefault()
            goToAuth("login")
          },
        },
        {
          id: "registration",
          title: "Registrieren",
          to: "",
          isButton: true,
          secondary: true,
          onClick: (e: React.MouseEvent) => {
            e.preventDefault()
            goToAuth("register")
          },
        },
      ]
}

const UserArea: React.FC<IUserAreaProps> = ({
  onLinkClick,
  isMobile,
  className,
}) => {
  const { goToAuth, isAuthenticated, logout } = useAuthentication()
  const linkClassName = clsx(classes.borderedButton, classes.buttonTypography)
  const translate = useTranslations()

  if (isAuthenticated && isMobile) {
    return null
  }

  const links = getLinks(isAuthenticated, logout, goToAuth, translate)
  const rootClassName = clsx(classes.root, className)

  return (
    <ul className={rootClassName}>
      {links.map((link) => {
        switch (link.id) {
          case "login":
            return (
              <li key={link.id} className={classes.item}>
                <AdviqoButton
                  label={link.title}
                  color="secondary"
                  variant="outlined"
                  testId="navbar-login"
                  onClick={(e: React.MouseEvent) => {
                    onLinkClick()
                    if (link.onClick) link.onClick(e)
                  }}
                  labelClassName={classes.buttonTypography}
                  buttonClassName={linkClassName}
                />
              </li>
            )
          case "logout":
            return (
              <li key={link.id} className={classes.item}>
                <LogoutButton
                  labelClassName={classes.buttonTypography}
                  onClick={(e: React.MouseEvent) => {
                    onLinkClick()
                    if (link.onClick) link.onClick(e)
                  }}
                />
              </li>
            )
          case "registration":
            return (
              <li key={link.id} className={classes.item}>
                <RegisterButton
                  className={classes.buttonTypography}
                  onClick={(e: React.MouseEvent) => {
                    onLinkClick()
                    if (link.onClick) link.onClick(e)
                  }}
                />
              </li>
            )
          case "account":
            return (
              <li key={link.id} className={classes.item}>
                <AdviqoLink
                  onClick={(e: React.MouseEvent) => {
                    onLinkClick()
                    if (link.onClick) link.onClick(e)
                  }}
                  to={link.to}
                  as={link.as}
                  data-testid={`navbar-${link.id}`}
                  className={linkClassName}
                >
                  {link.title}
                </AdviqoLink>
              </li>
            )
          default:
            return null
        }
      })}
    </ul>
  )
}

export default UserArea
